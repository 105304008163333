import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createDraftSafeSelectorCreator,
    current
} from "@reduxjs/toolkit";
import apiClient from "../../../auth/apiClient";
import { BuildTree, BuildErrors, normalizeEntities } from "./CoreEntityUtil";

import * as c from "../../../constants/";
import _ from "lodash";
import FacilityService from "../../../services/FacilityService";
import ValetAreaService from "../../../services/ValetAreaService";
import {FindEntity} from "../entities"
const facilityService = new FacilityService(apiClient);
const valetAreaService = new ValetAreaService(apiClient);


// Thunks

//TODO update to be called something different. Like updateContextEntities
export const LoadEntities = createAsyncThunk(
    "coreEntity/add",
    async ({ entityID }) => {
        const response = await apiClient.get(`entities/${entityID}/tree`);

        // Assuming response.data is a flat list of entities you can directly return.
        return response.data;
    }
);

//replaces the changeFacilities thunk in the entities slice
export const changeNewFacilities = createAsyncThunk(
    "coreEntities/facilities/change",
    async ({ userID, facilityID }, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { coreEntities } = state;

            if (!coreEntities.entities[facilityID]) {
                // Fetch and build new entities if it doesn't exist
                const entityResponse = await apiClient.get(`entities/${facilityID}/tree`);
                const entities = entityResponse.data;
                const permissionsResponse = await apiClient.get(`accounts/v2/permissions/${userID}?entityID=${facilityID}`);
                const permissions = permissionsResponse.data;
                // Return a result or object if needed for further use
                return { contextID: facilityID, contextPermissions: permissions, entitiesToAdd: entities };
            }
            return thunkAPI.dispatch(setContext(facilityID));
        } catch (error) {
            // Optionally handle error in a way that's suitable for your app
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);


// This Replaces the addAtNode
export const addNewEntities = createAsyncThunk(
    "coreEntities/add",
    async ({ entityID }, thunkAPI) => {
        const response = await apiClient.get(`entities/${entityID}/tree`);

        // since this endpoint returns the entire tree from top to bottom, just find the node we originally cared about and its children
        const normalizedData = normalizeEntities(response.data);

        return { entityId: entityID, newEntitiesToAdd: normalizedData };
    }
);

// This Replaces the getPermissionsAtEntity
export const addPermissionsToEntity = createAsyncThunk(
    `coreEntities/permissions`,
    async ({ userID, entityID, currentContextID }, thunkAPI) => {
        if (_.isNil(currentContextID)) {
            // Maintain backwards compatibility, this variable is not needed for non-FG contexts
            currentContextID = entityID;
        }

        const state = thunkAPI.getState();
        const { coreEntities } = state;
        let foundEntity = coreEntities.entities[entityID];

        // Fetch entity data if not found
        if (!foundEntity) {
            const response = await apiClient.get(`entities/${currentContextID}/rich`);
            foundEntity = response.data; // Assuming response structure has the entity data
        }

        // Fetch permissions
        const permissionsResponse = await apiClient.get(
            `accounts/v2/permissions/${userID}?entityID=${entityID}`
        );
        const fetchedPermissions = permissionsResponse.data[0];

        // Create a new copy of the entity with updated permissions
        const updatedEntity = {
            ...foundEntity,
            permissions:[
                ...fetchedPermissions.permissions?.map(
                    (permission) => permission.permissionName
                ),
                ...fetchedPermissions.groups
                    ?.map((group) => [...group.permissions])
                    .flat(),
            ] ?? [], // Add/replace the permissions
        };
        // Return the updated entity
        if (updatedEntity && updatedEntity.entityid) {
            return updatedEntity;
        } else {
            console.warn('Invalid entity returned from addPermissionsToEntity:', updatedEntity);
            return thunkAPI.rejectWithValue({ error: 'Invalid entity structure' });
        }
    }
);

// Replaces entitySettingsUpdate (seems odd  no implementation here but that's how it is in the entity slice)
export const updateEntitySettings = createAsyncThunk(
    "coreEntities/updateEntitySettings",
    async (bulkSettings, thunkAPI) => {
        if (bulkSettings[0].settingName === "setting.devicedisabled") {
            bulkSettings.push({
                userName: bulkSettings[0].userName,
                entityId: bulkSettings[0].entityId,
                settingName: "setting.laneclosedsign",
                settingValue: bulkSettings[0].settingValue,
            });
        }
        const response = await apiClient.put("v2/settings/bulk", bulkSettings, {
            headers: { "Content-Type": "application/json" },
        });
        return response.data;
    }
);

// Replaces entitySettingsDelete
export const deleteEntitySettings = createAsyncThunk(
    "coreEntities/deleteEntitySetting",
    async ({ entityId, settingName, settingValue }, thunkAPI) => {
        const response = await apiClient.delete(
            `v2/settings/${entityId}/${settingName}`
        );
        return response.data;
    }
);

// Replaces entityUpdate
export const updateEntity = createAsyncThunk(
    "coreEntities/entityUpdateGeneric",
    async (payload) => {
        const response = await apiClient.put(payload.path, payload.entity, {
            headers: { "Content-Type": "application/json" },
        });

        const richEntity = await apiClient.get(
            "entities/" +
            (response.data?.deviceID ??
                response.data?.facilityID ??
                response.data?.valetAreaID ??
                response.data?.areaID ??
                response.data) +
            "/rich/"
        );
        return richEntity.data;
    }
);
export const onboardNewFacility = createAsyncThunk(
    "coreEntities/facility/onboard",
    async (payload, thunkAPI) => {
        const result = await facilityService.onboardFacility(
            payload.parentEntityID,
            payload
        );
        return result.data;
    }
);
export const onboardValetArea = createAsyncThunk(
    "coreEntities/valetarea/onboard",
    async (payload, thunkAPI) => {
        const result = await valetAreaService.onboardValetArea(payload);
        return result.data;
    }
);

// Replaces entityCreate
export const createNewEntity = createAsyncThunk(
    "coreEntities/createEntityGeneric",
    async (payload, thunkAPI) => {
        const response = await apiClient.post(payload.path, payload.entity, {
            headers: { "Content-Type": "application/json" },
        });
        const richEntity = await apiClient.get(
            "entities/" +
            (response.data?.deviceID ??
                response.data?.facilityID ??
                response.data?.areaID ??
                response.data) +
            "/rich/"
        );
        return richEntity.data;
    }
);

export const deleteEntity = createAsyncThunk(
    "coreEntities/deleteEntityGeneric",
    async (payload, thunkAPI) => {
        const response = await apiClient.patch(
            payload.path,
            [payload.payload.entityid],
            { headers: { "Content-Type": "application/json" } }
        );
        return payload;
    }
);

/**
 * Used to retrieve all entities at a given level (will be moved to an org slice id imagine once I get there)
 * This whole thunk seems useless in this new paradigm but ill keep it for now just to be safe
 */
export const fillEntityLevel = createAsyncThunk(
    "CoreEntities/fill",
    async ({ entityID, userID, currentContextID }, thunkAPI) => {
        if (_.isNil(currentContextID)) {
            // maintain backwards compatability, this variable is not needed for non-FG contexts
            currentContextID = entityID;
        }
        // fetch all entities under the given context
        const nodes = await apiClient.get(`entities/${currentContextID}/tree`);
        const built = nodes.data;
        let neededNodes;
        if(built.length > 0) {
            built.forEach((entity) => {
                if (entity.entityid == currentContextID) {
                    neededNodes = entity;
                }
            })
        }
        console.log("fillEntityLevel neededNodes", neededNodes);
        // get all permissions for the given context
        const entityPermissions = await apiClient.get(
            `accounts/v2/permissions/${userID}?entityID=${entityID}`
        );
        // grab permissions that need set/updated/maintained in state.EntityList
        let permissionDict = {};
        entityPermissions.data.forEach((grouping) => {
            permissionDict[grouping.entityID] = grouping.permissions.map(
                (permission) => permission.permissionName
            );
        });
        applyPermissions(permissionDict, neededNodes);
        const parentID =
            neededNodes.parententityid == null
                ? neededNodes.entityid
                : neededNodes.parententityid;
        return { built, parentID };
    }
);

// Yeah, same comment as above. this can probably be removed or refactored when we get to the org slice reducer
function applyPermissions(permissionDict, node) {
    const permissions = permissionDict[node.entityid];
    if (permissions) node.permissions = permissions;
    if (node.children) {
        node.children.forEach((childNode) => {
            applyPermissions(permissionDict, childNode);
        });
    }
}


const entityAdapter = createEntityAdapter({
    selectId: (entity) => entity.entityid,
});

// Slice
const slice = createSlice({
    name: "coreEntities",
    initialState: entityAdapter.getInitialState({
        ContextID: null, //adding ContextID to the initial state
    }), // Initialize with adapter state
    reducers: {
        setEntities:(state, action) => {
            const { entityID, entities } = action.payload;

            state.ContextID = entityID;

            const updatedEntities = entities.map((entity) => ({
                ...entity,
                context: true, // Mark all loaded entities as part of the context
            }));

            entityAdapter.setAll(state, updatedEntities);
        },
        setContext: (state, action) => {
            const { contextID, entities } = action.payload;
            state.ContextID = contextID;
            const updatedEntities = entities.map((entity) => ({
                ...entity,
                context: true, // Mark all loaded entities as part of the context
            }));

            entityAdapter.upsertMany(state.entities, updatedEntities);
        },
        setEntityProperty:(state, action) => {
            const { entityId, property, value } = action.payload;
            const foundEntity = state.entities[entityId]

            if(foundEntity){
                foundEntity[property] = value
            }
        },
        setEntityStateIfOffline: (state, action) => {
            const { entityid, value } = action.payload;
            const foundEntity = state.entities[entityid]

            if(foundEntity && !foundEntity.state){
                foundEntity.state = value;
            }
        },
        setEntitiesProperty: (state, action) => {
            const {entities} = action.payload;
            entities.map(e => {
                const {entityid, property, value} = e;
                const foundEntity = state.entities[entityid]
                if (foundEntity) {
                    foundEntity[property] = value;
                }
            })
        },
        setSetting: (state, action) => {
            const { entityid, settingName, settingValue } = action.payload;
            const foundEntity = state.entities[entityid]
            if (!foundEntity) return;
            const setting = foundEntity.settings?.find(
                (x) => x.name.toLowerCase() === settingName.toLowerCase()
            );
            if (!setting) return;
            setting.value = settingValue;
        },
        setSettings: (state, action) => {
            action.payload.map((setting) => {
                const foundEntity =state.entities[setting.entityID];
                if (!foundEntity) return;
                const foundSetting = foundEntity.settings?.find(
                    (x) =>
                        x.name.toLowerCase() ===
                        setting.settingName.toLowerCase().replace("setting.", "")
                );
                if (!foundSetting) return;
                foundSetting.value = setting.settingValue;
            });
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(LoadEntities.fulfilled, (state, action) => {

                const { entityID } = action.meta.arg;

                state.ContextID = entityID;

                Object.values(state.entities).forEach((entity) => {
                    entity.context = false;
                });

                const updatedEntities = action.payload.map((entity) => ({
                    ...entity,
                    context: true, // Mark new entities as part of the context
                }));

                entityAdapter.upsertMany(state.entities, updatedEntities); // Add or update entities from the built data
            })
            .addCase(deleteEntity.fulfilled, (state, action) => {
                const { entityid } = action.payload.payload; // verify
                entityAdapter.removeOne(state,entityid);
            })
            .addCase(updateEntity.fulfilled, (state, action) => {
                const updatedEntity = action.payload;
                const entityId = updatedEntity.entityid || updatedEntity.entityid;

                if (!entityId) {
                    console.error("No valid entity ID found in the payload:", updatedEntity);
                    return;
                }

                const existingEntity = state.entities[entityId];

                const mergedEntity = {
                    ...existingEntity,
                    ...updatedEntity,
                    details: {
                        ...existingEntity?.details,
                        ...updatedEntity?.details,
                    },
                    settings: updatedEntity.settings || existingEntity?.settings,
                };

                entityAdapter.updateOne(state, {
                    id: entityId,
                    changes: mergedEntity,
                });
            })
            .addCase(onboardNewFacility.fulfilled, (state, action) => {
                let facilityMetaData = {
                    entityid: action.payload.facilityID,
                    parententityid: action.payload.parentEntityID,
                    entitytype: c.ENTITY_TYPE.Facility,
                    children: [],
                    show: false,
                    context: false
                };
                entityAdapter.upsertOne(state, facilityMetaData)
            })
            .addCase(onboardValetArea.fulfilled, (state, action) => {
                let valetareaMetaData = {
                    entityid: action.payload.valetareaID,
                    parententityid: action.payload.parentEntityID,
                    entitytype: c.ENTITY_TYPE.ValetArea,
                    children: [],
                    show: false, // what does this do?
                    context: false
                };
                entityAdapter.upsertOne(state,valetareaMetaData)
            })
            .addCase(createNewEntity.fulfilled, (state, action) => {
                const updatedPayload = {
                    ...action.payload,
                    context: false
                };
                entityAdapter.addOne(state,updatedPayload)
            })
            .addCase(updateEntitySettings.fulfilled, (state, action) => {
                // this isn't doing anything in both slices SO WHY DOES THIS EVEN EXIST!?!?
            })
            .addCase(deleteEntitySettings.fulfilled, (state, action) => {
                const { entityId, settingName } = action.meta.arg;

                const entity = state.entities[entityId];

                if (entity && entity.settings) {
                    // Filter out the setting with the specified name
                    entity.settings = entity.settings.filter(
                        (setting) => setting.name !== settingName
                    );
                }
            })
            .addCase(addPermissionsToEntity.fulfilled, (state, action) => {
                entityAdapter.upsertOne(state,action.payload)
            })
            .addCase(changeNewFacilities.fulfilled, (state, action) => {
                const { contextID , contextPermissions , entitiesToAdd } = action.payload;
                entitiesToAdd.forEach((entity) => {
                    if(entity.entityid === contextID){
                        entity.permissions =       [
                            ...contextPermissions[0].permissions?.map(
                                (permission) => permission.permissionName
                            ),
                            ...contextPermissions[0].groups
                                ?.map((group) => [...group.permissions])
                                .flat(),
                        ] ?? [];
                    }
                })

                Object.values(state.entities).forEach((entity) => {
                    entity.context = false;
                });

                entitiesToAdd.forEach((entity) => {
                    entity.context = true;
                });

                state.ContextID = contextID;

                const filteredEntities = Object.values(entitiesToAdd).filter(entity => entity !== undefined);

                entityAdapter.upsertMany(state,Object.values(filteredEntities))

            })
            .addCase(addNewEntities.fulfilled, (state, action) => {
                const { newEntitiesToAdd } = action.payload;
                entityAdapter.upsertMany(state, Object.values(newEntitiesToAdd))
            })
            .addCase(fillEntityLevel.fulfilled, (state, action) => {
                const { payload } = action;

                const parentEntity = state.entities[payload.parentID]

                if(!parentEntity && payload.parentID != null ){
                    entityAdapter.upsertMany(state,payload.built)
                }
            })
    },
});

// globalSelectors
const entitySelectors = entityAdapter.getSelectors((state) => state.coreEntities);

export const {
    selectAll: selectAllEntities, //Retrieve all entities as an array
    selectById: selectEntityById, // Retrieve a single entity by its ID
    selectIds: selectEntityIds, // Retrieves an array of EntityIDs,
    selectTotal: selectTotalEntities, // Retrieves the total number of EntityIDs
} = entitySelectors;


//Get entity of contextID
export const selectContextEntity = createSelector(
    (state) => state.coreEntities,
    (entities) => {
        const contextEntity = entities.entities[entities.ContextID];

        if (!contextEntity) {
            return null; // Return null if the context entity is not found
        }

        // Recursive function to find all children and their descendants
        const findChildren = (parentId) => {
            const directChildren = Object.values(entities.entities)
                .filter((entity) => entity.parententityid === parentId)
                .map(({ state, ...rest }) => rest); // Exclude the `state` field

            return directChildren.map((child) => ({
                ...child,
                children: findChildren(child.entityid), // Recursively find children for each child
            }));
        };

        return {
            ...contextEntity,
            children: findChildren(entities.ContextID), // Recursively find all children and descendants
        };
    }
);

export const selectContextEntityPermissions = createSelector(
    (state) => state.coreEntities,
    (entities) => {
        return entities.entities[entities.ContextID]?.permissions
    }
);

export const selectContextEntityName = createSelector(
    (state) => state.coreEntities,
    (entities) => {
        return entities.entities[entities.ContextID]?.name
    }
);

//Replaces the selectAllFacilities function
export const selectAllFacilities = createSelector(
    selectAllEntities,
    (entities) => entities.filter(({ type }) => type === c.ENTITY_TYPE.Facility)
)


//replaces the BuildTree Function
export const selectEntityTree = createSelector(
    (state) => state.coreEntities,
    (entities) => BuildTree(entities)
);
export const selectRawIDsWithScope = createSelector(
    selectAllEntities,
    (entities) => {
        return entities.map((entity) => ({
            entityId: entity.entityid,
            parentEntityId: entity.parententityid || null, // Return null if no parentEntityId
        }));
    }
);


export const selectAllScopedPermissions = createSelector(
    (state) => state.coreEntities, // Access the normalized state slice
    (coreEntities) => {
        const result = {};

        // Iterate through the IDs to access each entity
        coreEntities.ids.forEach((id) => {
            const entity = coreEntities.entities[id];
            if (entity && entity.permissions && entity.permissions.length > 0) {
                result[id] = entity.permissions
            }
        });

        return result;
    }
);

export const selectAllEntitySettings = createSelector(
    (state) => state.coreEntities, // Access the normalized state slice
    (coreEntities) => {
        const result = [];

        // Iterate through the IDs to access each entity
        coreEntities.ids.forEach((id) => {
            const entity = coreEntities.entities[id];
            if (entity && entity.settings && entity.settings.length > 0) {
                result.push({
                    entityID: id,
                    settings: entity.settings
                })
            }
        });

        return result;
    }
);

// @description Gets entities with no state field useful GetEntityTreeWithNoState
// if you need everything in context but don't want it to re-render everytime a DSD comes in
export const selectEntitiesWithNoState = createSelector(
    (state) => state.coreEntities, // Access normalized entities
    (entities) => {
        const result = {};
        for (const [key, entity] of Object.entries(entities.entities)) {
            result[key] = {
                entityId: entity.entityid,
                parentEntityId: entity.parententityid || null,
                typeId: entity.typeid,
            };
        }
        return result;
    }
);



export const createNearestFacilityFromEntity = (entityId) =>
    createSelector(
        (state) => state.coreEntities, // Access the coreEntities slice
        ({entities, ids}) => {
            let current = entities[entityId];
            let lastFound = null;

            while (current) {
                if (
                    current.typeid === c.ENTITY_TYPE.Facility ||
                    current.typeid === c.ENTITY_TYPE.ValetArea
                ) {
                    lastFound = current;
                }
                current = entities[current.parententityid]; // Move up the hierarchy
            }

            return lastFound;
        }
    );

//wrapper function for selectNearestFacilityGroup
export const selectNearestFacilityFromEntity = (entityId) => (state) => createNearestFacilityFromEntity(entityId)(state);

export const selectNearestFacilityGroupFromEntity = (entityId) =>
    createSelector(
        (state) => state.coreEntities, // Access the coreEntities slice
        ({ entities, ids }) => {
            // Validate the input entityId exists within the normalized entities
            if (!ids.includes(entityId)) {
                return null;
            }

            let currentEntity = entities[entityId];

            while (currentEntity) {
                if (currentEntity.typeid === c.ENTITY_TYPE.FacilityGroup) {
                    return currentEntity; // Found nearest FacilityGroup
                }
                const parentID = currentEntity.parententityid;
                if (!parentID || !ids.includes(parentID)) {
                    break; // Stop if no parentID or if parentID is not valid in the ids array
                }
                currentEntity = entities[parentID]; // Move to parent
            }

            return null; // No match found
        }
    );

export const selectAllDeviceIds = createSelector(
    (state) => state.coreEntities, // Access the coreEntities slice
    ({ entities, ids }) => {
        return ids
            .map((id) => entities[id])
            .filter((entity) => entity?.typeid === c.ENTITY_TYPE.Device)
            .map((entity) => entity.entityid);
    }
);

export const makeParentEntitySelector = (entityId) =>
    createSelector(
        (state) => state.coreEntities, // Access the coreEntities slice
        ({ entities }) => {
            const parentEntityId = entities[entityId]?.parententityid;
            return entities[parentEntityId] ?? {};
        }
    );
export const selectParentEntity = (entityId) => (state) => makeParentEntitySelector(entityId)(state);
//Functions
export const loadEntityList = (entityID) => async (dispatch) => {
    apiClient
        .get(`entities/${entityID}/tree`)
        .then((resp) => {
            dispatch(setEntities({ entityID, entities: resp.data })); //load the full entity tree into EntityList
        })
        .catch((err) => {
            console.error(
                "Encountered error while loading all entities from context:",
                err
            );
        })
        .finally(() => {
            //do nothing, getting IDs next
        });
};


export function DetermineIssuesOnGivenEntity(entity) {
    return BuildErrors(entity);
}


export const {
    setEntities,
    setContext,
    setEntityProperty,
    setEntityStateIfOffline,
    setEntitiesProperty,
    setSetting,
    setSettings
} = slice.actions;

export default slice.reducer;
