// Utility function to build a tree from the coreEntity.entities slice
import * as c from "../../../constants";
import _ from "lodash";
import {DetermineIssuesOnGivenEntity} from "../entities";

export const BuildTree = (slice) => {
    // Ensure entities exist
    const validSlice = slice || {};
    const {entities, ids} = validSlice;

    // Create an entity map and initialize children array
    const entityMap = ids.reduce((acc, id) => {
        acc[id] = { ...entities[id], children: [] };
        return acc;
    }, {});

    const rootNodes = [];

    // Populate the parent-child relationship using ids for controlled iteration
    ids.forEach((id) => {
        const entity = entityMap[id];
        if (entity && entity.context) { // Ensure entity exists and context is true
            if (entity.parententityid) {
                const parent = entityMap[entity.parententityid];
                if (parent) {
                    parent.children.push(entity);
                }
            } else {
                rootNodes.push(entity); // No parent implies it's a root node
            }
        }
    });

    return rootNodes[0]; // Return the first root node as specified
};


export const normalizeEntities = (entities) => {
    const result = {};
    entities.forEach((entity) => {
        if (result[entity.entityid]) {
            // Handle merging logic as needed, e.g., merging arrays or objects
            result[entity.entityid] = {
                ...result[entity.entityid],
                ...entity,  // You may need more complex merging depending on your data structure
            };
        } else {
            result[entity.entityid] = entity;
        }
    });
    return result;
};

//depth first search algo
export const FindEntity = (obj, targetId) => {
    return obj[targetId];
}

export const BuildErrors = (entity) => {
    let errorArray = [];
    if (entity.state == null) {
        errorArray.push({
            Type: c.ERROR_TYPES.Device,
            Severity: c.SEVERITY.CRITICAL,
            Message: "DEVICE NOT RESPONDING",
        });
        return errorArray; //if device isn't responding, don't bother testing everything else
    }


    if (entity?.state?.Peripherals?.CreditCardReader?.IsConfigured ?? false) {
        if (entity.state?.Peripherals?.CreditCardReader?.Tampered)
            errorArray.push({
                Type: c.ERROR_TYPES.CCReader,
                Severity: c.SEVERITY.WORLD_IS_ON_FIRE,
                Message: "CC Reader Detected Tampering",
            });
        if (entity.state?.Peripherals?.CreditCardReader?.NoCommunications)
            errorArray.push({
                Type: c.ERROR_TYPES.CCReader,
                Severity: c.SEVERITY.CRITICAL,
                Message: "CC Reader Not Found",
            });
        if (entity.state?.Peripherals?.CreditCardReader?.HostOffline)
            errorArray.push({
                Type: c.ERROR_TYPES.CCReader,
                Severity: c.SEVERITY.CRITICAL,
                Message: "CC Reader Host Offline",
            });
        if (entity.state?.Peripherals?.CreditCardReader?.ReaderOffline)
            errorArray.push({
                Type: c.ERROR_TYPES.CCReader,
                Severity: c.SEVERITY.CRITICAL,
                Message: "CC Reader Offline",
            });
    }

    if (entity?.state?.Peripherals?.BNR?.IsConfigured ?? false) {
        if (entity.state?.Peripherals?.BNR?.CashBoxFull)
            errorArray.push({
                Type: c.ERROR_TYPES.BNR,
                Severity: c.SEVERITY.WARNING,
                Message: "BNR Cash Box Full",
            });
        if (entity.state?.Peripherals?.BNR?.CashMachineStatus === 1)
            errorArray.push({
                Type: c.ERROR_TYPES.BNR,
                Severity: c.SEVERITY.WORLD_IS_ON_FIRE,
                Message: "BNR Requires Maintenance",
            });
        if (entity.state?.Peripherals?.BNR?.CashMachineStatus === 2)
            errorArray.push({
                Type: c.ERROR_TYPES.BNR,
                Severity: c.SEVERITY.WARNING,
                Message: "BNR Offline",
            });
    }

    //loops
    if (entity?.state?.Peripherals?.Gate?.IsConfigured ?? false) {
        if (entity.state?.Peripherals?.Gate?.ArmingLoop === c.LOOP_STATUS.Unset) {
            errorArray.push({
                Type: c.ERROR_TYPES.ArmingLoop,
                Severity: c.SEVERITY.LOW,
                Message: "Arming Loop Unknown",
            });
        }
        if (entity.state?.Peripherals?.Gate?.ClosingLoop === c.LOOP_STATUS.Unset) {
            errorArray.push({
                Type: c.ERROR_TYPES.ClosingLoop,
                Severity: c.SEVERITY.LOW,
                Message: "Closing Loop Unknown",
            });
        }
    }

    if (entity.state?.Peripherals?.Printer?.IsConfigured ?? false) {
        if (entity?.deviceMode === c.DEVICE_MODE.ENTRY && !entity?.rateid) {
            errorArray.push({
                Type: c.ERROR_TYPES.Rate,
                Severity: c.SEVERITY.WARNING,
                Message: "NO RATE ASSIGNED",
            });
        }

        if (entity.state?.Peripherals?.Printer?.Low) {
            errorArray.push({
                Type: c.ERROR_TYPES.Printer,
                Severity: c.SEVERITY.LOW,
                Message: "Printer Paper Low",
            });
        }
        if (entity.state?.Peripherals?.Printer?.Jam) {
            errorArray.push({
                Type: c.ERROR_TYPES.Printer,
                Severity: c.SEVERITY.CRITICAL,
                Message: "Printer Jammed",
            });
        }
        if (entity.state?.Peripherals?.Printer?.Out) {
            errorArray.push({
                Type: c.ERROR_TYPES.Printer,
                Severity: c.SEVERITY.CRITICAL,
                Message: "Printer Out of Paper",
            });
        }
        if (!entity.state?.Peripherals?.Printer?.Online) {
            errorArray.push({
                Type: c.ERROR_TYPES.Printer,
                Severity: c.SEVERITY.CRITICAL,
                Message: "Printer Offline",
            });
        }
    }
    return errorArray;
};

export const DetermineErrorClassesByPeripheralType = (
    _entity,
    _peripheralType
) => {
    let _classList = [];
    let _listByPeripheral =
        DetermineIssuesOnGivenEntity(_entity).filter(
            (e) => e.Type === _peripheralType
        ) ?? [];
    _listByPeripheral.map((_error) => {
        if (_error.Severity === c.SEVERITY.LOW) _classList.push("low");
        if (_error.Severity === c.SEVERITY.WARNING) _classList.push("warning");
        if (_error.Severity === c.SEVERITY.CRITICAL) _classList.push("critical");
        if (_error.Severity === c.SEVERITY.WORLD_IS_ON_FIRE)
            _classList.push("fire");
    });
    _classList.push("norm"); //just to test we can get multiples (sim printer / printer dsd not quite right)
    return _.uniq(_classList);
};



export function findNearestFacilityGroupFromEntity(entities, entityid) {
    let current = entities[entityid];
    let lastFound = null;
    while (current) {
        if (current.typeId === c.ENTITY_TYPE.FacilityGroup) {
            lastFound = current;
        }
        current = entities[current.parententityid]; // Move up the hierarchy
    }

    return lastFound;
}

export function findNearestFacilityFromEntity(entities, entityid) {
    let current = entities[entityid];
    let lastFound = null;
    while (current) {
        if (
            current.typeId === c.ENTITY_TYPE.Facility ||
            current.typeId === c.ENTITY_TYPE.ValetArea
        ) {
            lastFound = current;
        }
        current = entities[current.parentEntityId]; // Move up the hierarchy
    }

    return lastFound;
}


